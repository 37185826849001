import React from 'react';
import {
  WidgetProps,
  useEnvironment,
  createRtfFormatter,
  useTranslation,
  useExperiments,
  HttpClient,
} from '@wix/yoshi-flow-editor';
import {
  CommentsProps,
  WixCommentsApiProvider,
  WixComments,
  ContextFields,
} from '@wix/comments-ooi-client';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import { WixCommentsTranslationsProvider } from '@wix/comments-ooi-translations-provider';
import { useSettings } from '@wix/tpa-settings/react';
import { resolveCommentsConfig } from '../configuration/resolve-comments-config';
import { getPluginConfig } from '../configuration/get-plugin-config';
import { Category } from '../../../services/category-service';
import { getWidgetSettings } from '../configuration/get-widget-settings';
import { InfoAlert } from './info-alert/info-alert';
import { isWithVotes } from '../configuration/is-with-votes';
import { resolvePaginationConfig } from '../configuration/resolve-pagination-config';
import { classes, st } from './index.st.css';
import { generateDeepLink } from './utils/generate-deep-link';
import { getDeepLinkedComment } from './utils/get-deep-linked-comment';
import { isWithEmotions } from '../configuration/is-with-emotions';
import {
  DevSettingsConfig,
  resolveDevSettingsConfig,
} from '../configuration/resolve-dev-settings-config';

type WidgetRootInterface = {
  resourceId: string;
  isLocked: boolean;
  ctxFields: ContextFields;
  devSettingsConfig: DevSettingsConfig;
  category: Category | undefined;
  rootKey: string | undefined;
} & CommentsProps &
  WidgetProps;

const Widget: React.FC<WidgetRootInterface> = ({
  resourceId,
  isLocked,
  ctxFields,
  devSettingsConfig,
  category,
  isDemoMode,
  ...props
}) => {
  const { isMobile, isRTL, language } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const widgetSettings = React.useMemo(
    () => getWidgetSettings({ settingsGetter: settings.get, category, isMobile }),
    [settings, category, isMobile],
  );
  const httpClient = React.useMemo(() => new HttpClient(), []);
  const commentsSettings = React.useMemo(
    () => resolveCommentsConfig({ settings: widgetSettings, t }),
    [widgetSettings, t, experiments],
  );

  const devSettings = React.useMemo(
    () => resolveDevSettingsConfig(devSettingsConfig, props.wcDevToolsState),
    [devSettingsConfig, props.wcDevToolsState],
  );
  return (
    <div className={st(classes.root, { isMobile })} key={props.rootKey}>
      {isDemoMode && (
        <div className={classes.infoContainer}>
          <InfoAlert alertText={t('demoContent.alert')} />
        </div>
      )}
      <TPAComponentsProvider
        value={React.useMemo(() => ({ mobile: isMobile, rtl: isRTL }), [isMobile, isRTL])}
      >
        <WixCommentsApiProvider {...props} wcDevToolsState={devSettings}>
          <WixCommentsTranslationsProvider>
            <WixComments
              {...commentsSettings}
              httpClient={httpClient}
              resourceId={resourceId}
              isLocked={isLocked}
              ctxFields={ctxFields}
              // @ts-expect-error
              formatRelativeTime={React.useMemo(
                () =>
                  createRtfFormatter(
                    language,
                    { floorSeconds: true, extraShortExpression: false },
                    { localeMatcher: 'best fit', numeric: 'always', style: 'narrow' },
                  ),
                [language],
              )}
              pagination={React.useMemo(
                () =>
                  resolvePaginationConfig({
                    settings: {
                      sortCommentsBy: widgetSettings.sortCommentsBy,
                      repliesOnLoadMore: widgetSettings.repliesOnLoadMore,
                      commentsOnLoadMore: widgetSettings.commentsOnLoadMore,
                      commentsOnFirstPage: widgetSettings.commentsOnFirstPage,
                      repliesOnFirstPage: widgetSettings.repliesOnFirstPage,
                    },
                    withVotes: isWithVotes(widgetSettings.reactionType),
                    withEmotions: isWithEmotions(widgetSettings.reactionType),
                  }),
                [widgetSettings],
              )}
              deepLink={React.useMemo(
                () => ({
                  commentId: getDeepLinkedComment(resourceId),
                  generateLink: (deepLinkCommentId: string) =>
                    generateDeepLink(resourceId, deepLinkCommentId),
                }),
                [resourceId],
              )}
              pluginConfig={React.useMemo(() => getPluginConfig(widgetSettings), [widgetSettings])}
              blackAndWhiteModals
              transformCommentOnRender={undefined}
              screenshotMode={devSettingsConfig.screenshotMode}
            />
          </WixCommentsTranslationsProvider>
        </WixCommentsApiProvider>
      </TPAComponentsProvider>
    </div>
  );
};

export default Widget;
